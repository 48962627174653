<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }} Withdrawal</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Withdrawal Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Technician</label>

                    <select :class="['form-control', {'is-invalid': errorFor('user_id')}]"
                            :disabled="$route.name === 'withdrawals_edit'"
                            v-model="form.user_id">
                      <option value="">Select Technician</option>

                      <option :value="technician.id"
                              v-for="(technician, i) in technicians"
                              :key="'technician_'+i">{{ technician.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('user_id')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Type</label>
                    <select :class="['form-control', {'is-invalid': errorFor('type')}]"
                            :disabled="$route.name === 'withdrawals_edit'"
                            v-model="form.type">
                      <option value="">Select Type</option>
                      <option value="wow">Wow</option>
                      <option value="tip">Tip</option>
                      <option value="commission">Bonus</option>
                      <option value="gross">Gross</option>
                    </select>
                    <v-errors :errors="errorFor('type')" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text"
                           v-no-commas
                           :class="['form-control', {'is-invalid': errorFor('amount')}]"
                           v-model="form.amount">
                    <v-errors :errors="errorFor('amount')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>

              <router-link :to="{name: 'withdrawals'}"
                           class="btn btn-secondary">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      technicians: [],
      form: {
        type: '',
        amount: '',
        user_id: '',
      }
    }
  },
  computed: {
    title() {
      if (this.$route.name === 'withdrawals_add') {
        return "Add";
      } else {
        return "Edit";
      }
    }
  },
  created() {
    this.axios.get('/technicians')
      .then((response) => {
        this.technicians = response.data.data;
      })

    if (this.$route.name === 'withdrawals_edit') {
      this.axios.get('/withdrawals/' + this.$route.params.id)
          .then(res => {
            let withdrawal = res.data.data;

            this.form.type = withdrawal.type;
            this.form.amount = withdrawal.amount;
            this.form.user_id = withdrawal.user_id;
          })
    }
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'withdrawals_edit') {
        this.axios.patch('/withdrawals/' + this.$route.params.id, this.form)
            .then(() => {
              this.$router.push({name: 'withdrawals'});
              this.showSuccessMsg('Update Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.axios.post('/withdrawals', this.form)
            .then(() => {
              this.$router.push({name: 'withdrawals'});
              this.showSuccessMsg('Added Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }
  }
}
</script>